import axios from 'axios';

const API_URL = 'https://craftzapp.onrender.com/api'; // Asegúrate de usar la misma URL base.

const ventasApi = axios.create({
    baseURL: API_URL,
});

ventasApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
});

export const getVentas = async () => {
    const response = await ventasApi.get('/ventas');
    return response.data;
};

export const createVenta = async (venta) => {
    const response = await ventasApi.post('/ventas', venta);
    return response.data;
};

export default ventasApi;
