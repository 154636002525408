import React from 'react';

const ProductList = ({ products, deleteP }) => {
    return (
        <table border="1" style={{ width: '100%', marginTop: '20px' }}>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Precio</th>
                    <th>Stock</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <tr key={index}>
                            <td>{product.nombre}</td>
                            <td>{product.descripcion}</td>
                            <td>${product.precio}</td>
                            <td>{product.stock || 'N/A'}</td>
                            <td>
                            <button onClick={() => alert("Editar producto")}>Editar</button>
                            <button onClick={() => deleteP(product._id)}>Eliminar</button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>
                            No hay productos disponibles
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default ProductList;
