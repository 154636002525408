import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/AuthService';
import "../styles/Dashboard.css"
import Productos from "./ProductsPage"
import Ventas from "./Ventas"

const Dashboard = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token"); // Eliminar el token de autenticación
    navigate("/"); // Redirigir al inicio de sesión
  };

  const [activeSection, setActiveSection] = useState("inicio");

  const renderSection = () => {
    switch (activeSection) {
      case "inicio":
        return <div>Bienvenido al Dashboard</div>;
      case "productos":
        return <div><Productos/></div>;
      case "ventas":
        return <div><Ventas/></div>;
      case "logout":
        return <div><button onClick={handleLogout}>Cerrar sesión</button></div>
      default:
        return <div>Seleccione una opción</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <img src="https://i0.wp.com/craftzstore.com/wp-content/uploads/2024/05/Logo-Horizontal-1.png" alt="Logo"></img>
        <h2>Craftz Admin</h2>
        <nav>
          <button onClick={() => setActiveSection("inicio")}>Inicio</button>
          <button onClick={() => setActiveSection("productos")}>Productos</button>
          <button onClick={() => setActiveSection("ventas")}>Ventas</button>
          <button onClick={() => setActiveSection("logout")}>Cerrar Sesión</button>
        </nav>
      </aside>
      <main className="content">{renderSection()}</main>
    </div>
  );
};

export default Dashboard;
