import React, { useState, useEffect } from 'react';
import { getProducts } from '../services/api';
import { createVenta } from '../services/ventasApi';

const Ventas = () => {
    const [productos, setProductos] = useState([]);
    const [venta, setVenta] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const fetchProducts = async () => {
            const productosData = await getProducts();
            setProductos(productosData);
        };
        fetchProducts();
    }, []);

    const handleAgregarProducto = (producto) => {
        const existe = venta.find((item) => item.productoId === producto._id);
        if (existe) {
            setVenta(
                venta.map((item) =>
                    item.productoId === producto._id
                        ? { ...item, cantidad: item.cantidad + 1 }
                        : item
                )
            );
        } else {
            setVenta([...venta, { productoId: producto._id, cantidad: 1, precio: producto.precio }]);
        }
    };

    const calcularTotal = () => {
        const totalVenta = venta.reduce((acc, item) => acc + item.cantidad * item.precio, 0);
        setTotal(totalVenta);
    };

    const handleRegistrarVenta = async () => {
        try {
            await createVenta({
                productos: venta.map((item) => ({
                    producto: item.productoId,
                    cantidad: item.cantidad,
                })),
            });
            alert('Venta registrada con éxito');
            setVenta([]);
            setTotal(0);
        } catch (error) {
            console.error(error);
            alert('Error al registrar la venta');
        }
    };

    useEffect(() => {
        calcularTotal();
    }, [venta]);

    return (
        <div>
            <h1>Registrar Venta</h1>
            <div>
                <h2>Productos</h2>
                <ul>
                    {productos.map((producto) => (
                        <li key={producto._id}>
                            {producto.nombre} - ${producto.precio.toFixed(2)}
                            <button onClick={() => handleAgregarProducto(producto)}>Agregar</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Venta</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {venta.map((item) => {
                            const producto = productos.find((p) => p._id === item.productoId);
                            return (
                                <tr key={item.productoId}>
                                    <td>{producto.nombre}</td>
                                    <td>${item.precio.toFixed(2)}</td>
                                    <td>{item.cantidad}</td>
                                    <td>${(item.cantidad * item.precio).toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <h3>Total: ${total.toFixed(2)}</h3>
                <button onClick={handleRegistrarVenta}>Registrar Venta</button>
            </div>
        </div>
    );
};

export default Ventas;
