import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
  const [correo, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('https://craftzapp.onrender.com/auth/login', {
        correo,
        password,
      });

      // Guarda el token en el localStorage
      localStorage.setItem('token', response.data.token);

      // Redirige al dashboard
      navigate('/dashboard');
    } catch (err) {
      setError('Credenciales inválidas o error en el servidor.');
    }
  };

  return (
    <div class="login-content">
      <div class="imagen">
        <img src = "https://i0.wp.com/craftzstore.com/wp-content/uploads/2024/05/cropped-Logo-Horizontal-1.png" alt="logo"></img>
      </div>
      <div>
      <h1>Iniciar Sesión</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={correo}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Contraseña:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Iniciar Sesión</button>
      </form>
      </div>
    </div>
  );
};

export default LoginPage;
