import React from 'react';

const HomePage = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Bienvenido al Sistema de Administración</h1>
            <p>Administra tus productos, inventarios y ventas fácilmente.</p>
        </div>
    );
};

export default HomePage;
