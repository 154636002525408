import React, { useState } from 'react';

const ProductForm = ({ onSubmit }) => {

    const [error, setError] = useState(null);

    const [product, setProduct] = useState({
        nombre: '',
        descripcion: '',
        precio: '',
        stock: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (onSubmit) {
                await onSubmit(product); // Llama a la función proporcionada por el padre.
            }
            setError(null); // Reinicia el estado de error.
            setProduct({ nombre: '', descripcion: '', precio: '', stock: '' }); // Reinicia el formulario.
        } catch (err) {
            setError('Error al agregar el producto. Inténtalo nuevamente.');
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ margin: '20px 0' }}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div>
                <label>Nombre del Producto:</label>
                <input
                    type="text"
                    name="nombre"
                    value={product.nombre}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label>Descripción:</label>
                <textarea
                    name="descripcion"
                    value={product.descripcion}
                    onChange={handleChange}
                    required
                ></textarea>
            </div>
            <div>
                <label>Precio:</label>
                <input
                    type="number"
                    name="precio"
                    value={product.precio}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label>Stock:</label>
                <input
                    type="number"
                    name="stock"
                    value={product.stock}
                    onChange={handleChange}
                />
            </div>
            <button type="submit">Agregar Producto</button>
        </form>
    );
};

export default ProductForm;
