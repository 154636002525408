import axios from 'axios';

const API_URL = 'https://craftzapp.onrender.com/api'; // Cambia esta URL si tu backend usa un puerto diferente.

const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
});


export const getProducts = async () => {
    const response = await api.get('/productos');
    return response.data;
};

export const createProduct = async (product) => {
    const response = await api.post('/productos', product);
    return response.data;
};

export const deleteProduct = async (id) => {
    const response = await api.delete('/productos', id);
    return response.data;
};

export default api;
