import React, { useState, useEffect } from 'react';
import ProductForm from '../components/ProductForm';
import ProductList from '../components/ProductList';
import { getProducts, createProduct, deleteProduct } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/AuthService';
import "../styles/Productos.css";

const ProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isAuthenticated()) {
          navigate('/');
        }
      }, [navigate]);

    // Cargar productos desde el backend al montar el componente.
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const data = await getProducts();
                setProducts(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los productos:', error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleAddProduct = async (newProduct) => {
        try {
            const addedProduct = await createProduct(newProduct);
            setProducts([...products, addedProduct]); // Actualizar la lista de productos.
        } catch (error) {
            console.error('Error al agregar el producto:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
          const deletedProduct = await deleteProduct(id);
          setProducts(products.filter((producto) => producto._id !== id));
        } catch (error) {
          console.error("Error al eliminar producto:", error);
        }
    };

    if (loading) {
        return <div>Cargando productos...</div>;
    }

    return (
    <div className="productos-container">
        <h2>Gestión de Productos</h2>
        <ProductForm onSubmit={handleAddProduct} />
        <ProductList products={products} deleteP={handleDelete} />
    </div>
    );
};

export default ProductsPage;
